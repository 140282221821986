<template>
    <h1>404 Page Not Found(你走錯路了）</h1>
</template>

<script>
    export default {
        name: "PageNotFound404"
    }
</script>

<style scoped>

</style>